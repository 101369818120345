<script lang="ts" setup>
import BasicButton from '~/components/form-elements/BasicButton.vue'
import ConsultationStatus from '~/enums/consultation-status'
import PageEnum from '~/enums/page'

defineProps<{
  status: ConsultationStatus
}>()

const router = useRouter()
</script>

<template>
  <div class="flex min-h-[50vh] flex-col items-center justify-center">
    <b class="mb-5 text-lg font-medium md:mb-10 md:text-[40px]">
      <template v-if="status === ConsultationStatus.Canceled">
        Отменённых
      </template>
      <template v-else-if="status === ConsultationStatus.Completed">
        Завершенных
      </template>
      <template v-else>
        Предстоящих
      </template> консультаций нет
    </b>

    <BasicButton
      class="!h-14 !w-[300px] !rounded-2xl !text-base"
      color="green"
      is-small
      title="Записаться к специалисту"
      @click="router.push({ path: PageEnum.Specialists })"
    />
  </div>
</template>
